export default {
    data() {
        return {
            initLoading:true,
            parameter:{
                condition:{},
                pageNumber:1,
                pageSize:50,
                total:0,
            },
            api:{},
            formConfig:{
                info:{},
                list:[],
            },
            searchData:[],
            advancedSearchShow:false,
            options: {},
            tableData: [],
            tableColumnData:[],
            loading: false,
            tableSettingVisible: false,
            showEditDialog: false,
            elTable:{
                stripe:true,
                border:true,
            }
        };
    },
    watch:{
        tableColumnData:{
            deep: true,//对象深度监测
            handler: function (){
                this.$nextTick(() => {
                    this.$refs.multipleTable.doLayout();
                });
            }
        },
        advancedSearchShow(){
            this.$nextTick(() => {
                this.$refs.multipleTable.doLayout();
            });
        },
    },
    activated(){
        this.$refs.multipleTable.doLayout();
    },
    methods: {
        reset(){
            this.searchData.some(r=>{
                r.fieldVal = '';
            });
            this.advancedSearchShow = false;
            this.pageChangeHandler(1);
        },
        init(){
            this.$api['admin/formConfig'].getField(this.$route.meta.tableName).then( res => {

                this.parameter.condition.tableName = res.data.info.tableName;
                var arr = [];
                res.data.list.some(r=>{
                    r.fieldName = r.fieldName.toLowerCase();
                    this.tableColumnData.push({
                        prop:r.fieldName,
                        label:r.fieldLabel?r.fieldLabel:r.fieldName,
                        isShow:r.listIsShow=='true'?true:false,
                        sortable:r.sortable=='custom'?'custom':false,
                        fixed:false,
                    })

                    let obj = {
                        fieldName:r.fieldName,
                        fieldLabel:r.fieldLabel,
                        fieldVal:'',
                        searchType:'like',
                        type:r.search=='true'?1:0,
                    }
                    if(obj.type){
                        this.searchData.push(obj)
                    };
                    arr.push(obj)
                })
                this.formConfig = res.data;
                this.parameter.condition.fields = arr;
                this.initLoading = false;
                this.getData();

            }).catch(err=>{
                console.log(err);
                this.initLoading = false;
            })
        },
        refresh(){
            this.showEditDialog = false;
            this.getData();
        },
        pageChangeHandler(val) {
            this.parameter.pageNumber = val;
            this.getData();
        },
        handleSizeChange(val){
            this.parameter.pageSize = val;
            this.parameter.pageNumber = 1;
            this.getData();
        },
        add() {
            this.options = {};
            this.showEditDialog = true;
        },
        edit(row) {
            this.options = row;
            this.showEditDialog = true;
        },
        sortChange(r){
            this.parameter.sort = r.order;// ascending  descending null
            this.parameter.sortProp = r.prop;
            this.pageChangeHandler(1);
        },
        getData() {
            this.loading = true;

            this.api.findPage(this.parameter).then(res => {
                this.loading = false;
                this.tableData = res.data.records;
                this.parameter.total = res.data.total;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        del(scope) {console.log(scope)
            var ids = [];
            if(scope){
                ids.push(scope.row.id)
            }else {
                if(this.$refs.multipleTable.selection.length){
                    this.$refs.multipleTable.selection.some(r=>{
                        ids.push(r.id)
                    })
                }else {
                    this.$message.error("请选择数据！");
                    return false
                }

            }
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.api.del({ids}).then(res => {

                    this.$message.success("删除成功！");

                    var hasLastPage = this.parameter.pageSize*this.parameter.pageNumber>=this.parameter.total;
                    var lastData = this.tableData.length<2;
                    if (lastData&&hasLastPage&&this.parameter.pageNumber>1) {
                        this.parameter.pageNumber --;
                    };
                    this.getData();


                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
    },
};
